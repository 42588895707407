<script setup lang="ts">
import IcpComp from '@/components/IcpComp.vue';
import bus from '@haa/fwk-h5/lib/bus';
import { Haa, OeReportType, OeReportSubType } from '@haa/fwk-h5/lib/index';
import { ref, onUnmounted } from 'vue';
const { strategy } = Haa.util().useStrategy();
const { oeReport } = Haa.util().useOeReport();
import { useRouter } from 'vue-router';
const { openWindow } = Haa.util().useOeReport();

Haa.util().useLifeCycle();

enum EventNams {
    Touchstart = 'touchstart',
    Click = 'click'
}

/** 默认使用touchstart触发点击 */
let hotEventName = ref(EventNams.Touchstart);
let notHotEventName = ref(EventNams.Touchstart);

const handleEventName = (type: number) => {
    // 0：全部touch_down触发跳转(默认);
    // 1：热点区域click触发，非热点区域touch_down触发跳转；
    const handleMap = [
        [EventNams.Touchstart, EventNams.Touchstart],
        [EventNams.Click, EventNams.Touchstart],
        [EventNams.Touchstart, EventNams.Click],
        [EventNams.Click, EventNams.Click],
    ]

    const [_hotEventName, _notHotEventName] = handleMap[type];

    _hotEventName && (hotEventName.value = _hotEventName);
    _notHotEventName && (notHotEventName.value = _notHotEventName);
}

import { useParams } from '@/store/useParams';
const { params } = useParams();
let globalClickable = ref<boolean>(false), timeout = ref<number>(-1), strategyTimer: any = null;
const setGuideParams = () => {
    handleEventName(strategy.guide_used_click);

    if (strategy.guide_click_enable == 1) globalClickable.value = true;
    if (strategy.guide_timeout >= 0) timeout.value = strategy.guide_timeout;
    if (timeout.value > -1) {
        strategyTimer = setTimeout(() => {
            oeReport(OeReportType.Action, OeReportSubType.BtnClick, 'Guide', '策略倒计时进入');
            routerNext('');
        }, timeout.value * 1000);
    } else if (params.req_id || params.adid) {
        strategyTimer = setTimeout(() => {
            oeReport(OeReportType.Action, OeReportSubType.BtnClick, 'Guide', '非审核链接直接1秒进入');
            routerNext('');
        }, 1000);
    }
}
if (strategy.status != 0) {
    setGuideParams();
} else {
    bus.on('strategy', setGuideParams);
}
onUnmounted(() => {
    if (strategyTimer) {
        clearTimeout(strategyTimer);
        strategyTimer = null;
    }
})

const handleRootTouch = () => {
    if (!globalClickable.value) return;
    oeReport(OeReportType.Action, OeReportSubType.BtnClick, 'Guide', 'touch非点击区域');
    routerNext('');
}


const router = useRouter();
const routerNext = (path: string) => {
    let result = openWindow({ skip: '1' });
    if (!result) router.replace({ path: '/main' });
}
const handleHotClick = (path: string) => {
    oeReport(OeReportType.Action, OeReportSubType.BtnClick, 'Guide', 'touch可点击区域');
    routerNext(path);
}
</script>

<template>
    <div class="guide-view-wrapper" v-on:[notHotEventName]="handleRootTouch">
        <div class="guide-view-box">
            <img class="main" src="@/assets/images/common/guide_main.png" />
            <div class="guide-options">
                <div class="option-item animate__animated animate__backInLeft" v-on:[hotEventName]="handleHotClick('classroom')" @touchstart.stop>
                    <img src="@/assets/images/common/guide_1.png" />
                    <div class="title">猫狗小课堂</div>
                    <div class="desc">你的一静一动</div>
                    <div class="desc">牵引着我得所有目光</div>
                    <div class="click-btn animate__animated animate__rubberBand animate__delay-1s">进入</div>
                </div>
                <div class="option-item animate__animated animate__backInRight animate__delay-1s" v-on:[hotEventName]="handleHotClick('wikipedia')" @touchstart.stop>
                    <img src="@/assets/images/common/guide_2.png" />
                    <div class="title">宠物百科</div>
                    <div class="desc">让我看看你这小东西</div>
                    <div class="desc">都有什么小秘密</div>
                    <div class="click-btn animate__animated animate__rubberBand animate__delay-2s">进入</div>
                </div>
                <div class="option-item animate__animated animate__backInLeft animate__delay-2s" v-on:[hotEventName]="handleHotClick('mood')" @touchstart.stop>
                    <img src="@/assets/images/common/guide_3.png" />
                    <div class="title">心情测试机</div>
                    <div class="desc">快让我测测</div>
                    <div class="desc">它现在心情怎么样</div>
                    <div class="click-btn animate__animated animate__rubberBand animate__delay-3s">进入</div>
                </div>
                <div class="option-item animate__animated animate__backInRight animate__delay-3s" v-on:[hotEventName]="handleHotClick('constellation')" @touchstart.stop>
                    <img src="@/assets/images/common/guide_4.png" />
                    <div class="title">宠物星座</div>
                    <div class="desc">喵喵教 & 汪汪教</div>
                    <div class="desc">看看它是什么星座吧</div>
                    <div class="click-btn animate__animated animate__rubberBand animate__delay-4s">进入</div>
                </div>
            </div>
        </div>

        <wap-native space-key="Guide_native"></wap-native>
        
        <div class="fixed-bottom">
            <icp-comp></icp-comp>
        </div>
        <wap-banner space-key="Guide_banner"></wap-banner>
    </div>
</template>

<style scoped lang="less">
.guide-view-wrapper {
    height: 100vh;
    .guide-view-box {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;left: 0;
        overflow: hidden;
        background-image: url('@/assets/images/common/guide_bg.png');
        background-size: 100% 100%;
        padding: 0 50px;
        box-sizing: border-box;
    }
    .main {
        width: 100%;
    }
    .guide-options {
        display: flex;
        flex-wrap: wrap;
        .option-item {
            width: 315px;
            height: 430px;
            background-color: #fff;
            border-radius: 30px;
            padding: 20px;
            box-sizing: border-box;
            margin-bottom: 20px;
            &:nth-child(odd) {
                margin-right: 20px;
            }
            img {
                width: 100%;
            }
            .title {
                font-size: 36px;
                color: #000;
                font-weight: 700;
                margin-bottom: 10px;
            }
            .desc {
                font-size: 26px;
                color: #6B6B6B;
                line-height: 38px;
            }
            .click-btn {
                margin-top: 20px;
                width: 150px;
                height: 66px;
                line-height: 66px;
                text-align: center;
                border-radius: 16px;
                background: linear-gradient(299.01deg, #8627FF 21.06%, #46D3FF 99.09%);
                font-size: 30px;
                color: #FFFFFF;
            }
        }
    }
    .fixed-bottom {
        width: 100%;
        position: absolute;
        bottom: 0;left: 0;
    }
}
</style>
