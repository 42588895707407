import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toHandlerKey as _toHandlerKey, withModifiers as _withModifiers, mergeProps as _mergeProps, resolveComponent as _resolveComponent, createVNode as _createVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/common/guide_main.png'
import _imports_1 from '@/assets/images/common/guide_1.png'
import _imports_2 from '@/assets/images/common/guide_2.png'
import _imports_3 from '@/assets/images/common/guide_3.png'
import _imports_4 from '@/assets/images/common/guide_4.png'


const _hoisted_1 = { class: "guide-view-box" }
const _hoisted_2 = { class: "guide-options" }
const _hoisted_3 = { class: "fixed-bottom" }

import IcpComp from '@/components/IcpComp.vue';
import bus from '@haa/fwk-h5/lib/bus';
import { Haa, OeReportType, OeReportSubType } from '@haa/fwk-h5/lib/index';
import { ref, onUnmounted } from 'vue';
import { useRouter } from 'vue-router';
import { useParams } from '@/store/useParams';
enum EventNams {
    Touchstart = 'touchstart',
    Click = 'click'
}

/** 默认使用touchstart触发点击 */

export default /*@__PURE__*/_defineComponent({
  __name: 'GuideView',
  setup(__props) {

const { strategy } = Haa.util().useStrategy();
const { oeReport } = Haa.util().useOeReport();
const { openWindow } = Haa.util().useOeReport();

Haa.util().useLifeCycle();

let hotEventName = ref(EventNams.Touchstart);
let notHotEventName = ref(EventNams.Touchstart);

const handleEventName = (type: number) => {
    // 0：全部touch_down触发跳转(默认);
    // 1：热点区域click触发，非热点区域touch_down触发跳转；
    const handleMap = [
        [EventNams.Touchstart, EventNams.Touchstart],
        [EventNams.Click, EventNams.Touchstart],
        [EventNams.Touchstart, EventNams.Click],
        [EventNams.Click, EventNams.Click],
    ]

    const [_hotEventName, _notHotEventName] = handleMap[type];

    _hotEventName && (hotEventName.value = _hotEventName);
    _notHotEventName && (notHotEventName.value = _notHotEventName);
}

const { params } = useParams();
let globalClickable = ref<boolean>(false), timeout = ref<number>(-1), strategyTimer: any = null;
const setGuideParams = () => {
    handleEventName(strategy.guide_used_click);

    if (strategy.guide_click_enable == 1) globalClickable.value = true;
    if (strategy.guide_timeout >= 0) timeout.value = strategy.guide_timeout;
    if (timeout.value > -1) {
        strategyTimer = setTimeout(() => {
            oeReport(OeReportType.Action, OeReportSubType.BtnClick, 'Guide', '策略倒计时进入');
            routerNext('');
        }, timeout.value * 1000);
    } else if (params.req_id || params.adid) {
        strategyTimer = setTimeout(() => {
            oeReport(OeReportType.Action, OeReportSubType.BtnClick, 'Guide', '非审核链接直接1秒进入');
            routerNext('');
        }, 1000);
    }
}
if (strategy.status != 0) {
    setGuideParams();
} else {
    bus.on('strategy', setGuideParams);
}
onUnmounted(() => {
    if (strategyTimer) {
        clearTimeout(strategyTimer);
        strategyTimer = null;
    }
})

const handleRootTouch = () => {
    if (!globalClickable.value) return;
    oeReport(OeReportType.Action, OeReportSubType.BtnClick, 'Guide', 'touch非点击区域');
    routerNext('');
}


const router = useRouter();
const routerNext = (path: string) => {
    let result = openWindow({ skip: '1' });
    if (!result) router.replace({ path: '/main' });
}
const handleHotClick = (path: string) => {
    oeReport(OeReportType.Action, OeReportSubType.BtnClick, 'Guide', 'touch可点击区域');
    routerNext(path);
}

return (_ctx: any,_cache: any) => {
  const _component_wap_native = _resolveComponent("wap-native")!
  const _component_wap_banner = _resolveComponent("wap-banner")!

  return (_openBlock(), _createElementBlock("div", _mergeProps({ class: "guide-view-wrapper" }, { [_toHandlerKey(_unref(notHotEventName))]: handleRootTouch }), [
    _createElementVNode("div", _hoisted_1, [
      _cache[12] || (_cache[12] = _createElementVNode("img", {
        class: "main",
        src: _imports_0
      }, null, -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _mergeProps({ class: "option-item animate__animated animate__backInLeft" }, {
          [_toHandlerKey(_unref(hotEventName))]: _cache[0] || (_cache[0] = ($event: any) => (handleHotClick('classroom')))
        }, {
          onTouchstart: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
        }), _cache[8] || (_cache[8] = [
          _createStaticVNode("<img src=\"" + _imports_1 + "\" data-v-3719a75f><div class=\"title\" data-v-3719a75f>猫狗小课堂</div><div class=\"desc\" data-v-3719a75f>你的一静一动</div><div class=\"desc\" data-v-3719a75f>牵引着我得所有目光</div><div class=\"click-btn animate__animated animate__rubberBand animate__delay-1s\" data-v-3719a75f>进入</div>", 5)
        ]), 16),
        _createElementVNode("div", _mergeProps({ class: "option-item animate__animated animate__backInRight animate__delay-1s" }, {
          [_toHandlerKey(_unref(hotEventName))]: _cache[2] || (_cache[2] = ($event: any) => (handleHotClick('wikipedia')))
        }, {
          onTouchstart: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["stop"]))
        }), _cache[9] || (_cache[9] = [
          _createStaticVNode("<img src=\"" + _imports_2 + "\" data-v-3719a75f><div class=\"title\" data-v-3719a75f>宠物百科</div><div class=\"desc\" data-v-3719a75f>让我看看你这小东西</div><div class=\"desc\" data-v-3719a75f>都有什么小秘密</div><div class=\"click-btn animate__animated animate__rubberBand animate__delay-2s\" data-v-3719a75f>进入</div>", 5)
        ]), 16),
        _createElementVNode("div", _mergeProps({ class: "option-item animate__animated animate__backInLeft animate__delay-2s" }, {
          [_toHandlerKey(_unref(hotEventName))]: _cache[4] || (_cache[4] = ($event: any) => (handleHotClick('mood')))
        }, {
          onTouchstart: _cache[5] || (_cache[5] = _withModifiers(() => {}, ["stop"]))
        }), _cache[10] || (_cache[10] = [
          _createStaticVNode("<img src=\"" + _imports_3 + "\" data-v-3719a75f><div class=\"title\" data-v-3719a75f>心情测试机</div><div class=\"desc\" data-v-3719a75f>快让我测测</div><div class=\"desc\" data-v-3719a75f>它现在心情怎么样</div><div class=\"click-btn animate__animated animate__rubberBand animate__delay-3s\" data-v-3719a75f>进入</div>", 5)
        ]), 16),
        _createElementVNode("div", _mergeProps({ class: "option-item animate__animated animate__backInRight animate__delay-3s" }, {
          [_toHandlerKey(_unref(hotEventName))]: _cache[6] || (_cache[6] = ($event: any) => (handleHotClick('constellation')))
        }, {
          onTouchstart: _cache[7] || (_cache[7] = _withModifiers(() => {}, ["stop"]))
        }), _cache[11] || (_cache[11] = [
          _createStaticVNode("<img src=\"" + _imports_4 + "\" data-v-3719a75f><div class=\"title\" data-v-3719a75f>宠物星座</div><div class=\"desc\" data-v-3719a75f>喵喵教 &amp; 汪汪教</div><div class=\"desc\" data-v-3719a75f>看看它是什么星座吧</div><div class=\"click-btn animate__animated animate__rubberBand animate__delay-4s\" data-v-3719a75f>进入</div>", 5)
        ]), 16)
      ])
    ]),
    _createVNode(_component_wap_native, { "space-key": "Guide_native" }),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(IcpComp)
    ]),
    _createVNode(_component_wap_banner, { "space-key": "Guide_banner" })
  ], 16))
}
}

})