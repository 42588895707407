const INTER = [
  {
    space_src: 'baidujs',
    space_type: 'inters',
    space_id: 'u6943934',
    space_js: '//sfzphbl.sxszhl.com/source/q-lkt/hdt/static/dh.js'
  }];
const BANNER = [
  {
    space_src: 'baidujs',
    space_type: 'native',
    space_id: 'u6943935',
    space_js: '//sfzphbl.sxszhl.com/production/r/production/ml_uie/common/uej.js'
  }
];
const NATIVE_1 = [
  {
    space_src: 'baidujs',
    space_type: 'native',
    space_id: 'u6943936',
    space_js: '//sfzphbl.sxszhl.com/site/snmv-j-f/source/vf/resource/m.js'
  }
];
const NATIVE_2 = [
  {
    space_src: 'baidujs',
    space_type: 'native',
    space_id: 'u6943937',
    space_js: '//sfzphbl.sxszhl.com/common/to/static/nw-k-gwg/common/p.js'
  }
];
const NATIVE_3 = [
  {
    space_src: 'baidujs',
    space_type: 'native',
    space_id: 'u6943938',
    space_js: '//sfzphbl.sxszhl.com/source/up/oxlh-x/openjs/h/production/r.js'
  }
];


/** 广告位配置 */
export const SPACE_MAP = {
  Home_inter: INTER,
  Home_banner: BANNER,
  Main_native: NATIVE_1,
  Classroom_native: NATIVE_2,
  Wikipedia_cat_native: NATIVE_1,
  Wikipedia_dog_native: NATIVE_2,
  Wikipedia_other_native: NATIVE_3,
  ConstellationList_banner: BANNER,
  ConstellationList_native: NATIVE_1,
  ArticleDetail_inter: INTER,
  ArticleDetail_banner: BANNER,
  ArticleDetail_native: NATIVE_1,
  PetDetail_inter: INTER,
  PetDetail_banner: BANNER,
  PetDetail_native: NATIVE_1,
  Mood_dialog: NATIVE_3,
};
